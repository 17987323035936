/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'semantic-ui-css/semantic.css';
import 'jquery';
import 'semantic-ui-css/semantic.js';
import Rails from 'rails-ujs';

$(function() {
  $('.ui.checkbox').checkbox();

  $('#button-csv-import').click((e) => {
    e.preventDefault();
    $('#modal-csv-import').modal({
      onApprove: () => { return false; } //don't auto close modal
    }).modal('show');
  });

  $('#button-edit-user').click((e) => {
    e.preventDefault();
    $('#modal-edit-user').modal({
      onApprove: () => { return false; } //don't auto close modal
    }).modal('show');
  });

  $('#submit-form-csv-import').click((e) => {
    e.preventDefault();
    $('#form-csv-import').submit();
  });

  $('.js-vacancies-search-form submit').click((e) =>{
    $('.js-vacancies-search-form').addClass('loading');
  });
});

$(function() {
  $('.ui.form').form({
    fields: {
      email: {
        identifier  : 'owner_email',
          rules: [
            {
              type   : 'empty',
              prompt : 'メールアドレスを入力してください'
            },
            {
              type   : 'email',
              prompt : '正常なメールアドレスを入力してください'
            }
          ]
        },
        password: {
          identifier  : 'owner_password',
          rules: [
            {
              type   : 'empty',
              prompt : 'パスワードを入力してください'
            },
            {
              type   : 'length[6]',
              prompt : 'パスワードは６文字以上で入力してください'
            }
          ]
      }
    }
  });
});

Rails.start();
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
