import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

class MonthSelector extends React.Component {
  constructor(props) {
    super(props);
    const date = moment();
    const month = date.month();
    const year = date.year();
    if(this.props.from && this.props.till) {
      this.state = {
        from: moment(this.props.from).format("YYYY-MM-DD"),
        till: moment(this.props.till).format("YYYY-MM-DD")
      };
    } else {
      this.state = {
        from: moment({years: year, months: month}).startOf('month').format("YYYY-MM-DD"),
        till: moment({years: year, months: month}).endOf('month').format("YYYY-MM-DD")
      };
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if(e.target.value < this.state.from) {
      this.setState({
        from: moment(e.target.value).startOf('month').format("YYYY-MM-DD"),
        till: this.state.till
      });
    } else if(this.state.till < e.target.value) {
      this.setState({
        from: this.state.from,
        till: moment(e.target.value).endOf('month').format("YYYY-MM-DD")
      });
    } else {
      if(moment(e.target.value).diff(moment(this.state.from)) < moment(this.state.till).diff(moment(e.target.value))) {
        this.setState({
            from: this.state.from,
            till: moment(e.target.value).endOf('month').format("YYYY-MM-DD")
        });
      } else {
        this.setState({
            from: moment(e.target.value).startOf('month').format("YYYY-MM-DD"),
            till: this.state.till
        });
      }
    }
  }

  render () {
    const date = moment();
    return [...Array(18).keys()].map((key) => {
      var year = date.clone().add(key, 'months').year();
      var month = date.clone().add(key, 'months').month() + 1;
      var yyyymmdd = moment({years: year, months: month-1}).startOf('month').format("YYYY-MM-DD");
      return (
        <React.Fragment key={yyyymmdd}>
          {month === 1 && <div className="c-yearCol">{year}年</div>}
          <div className="cb-btn">
            <label>
              <input
                type='checkbox'
                name='month'
                value={yyyymmdd}
                checked={this.state.from <= yyyymmdd && yyyymmdd <= this.state.till}
                onChange={this.handleChange}
              />
              <span className="btn">{month}月</span>
            </label>
          </div>
          <input
            type='hidden'
            name='month_from'
            value={this.state.from}
          />
          <input
            type='hidden'
            name='month_till'
            value={this.state.till}
          />
        </React.Fragment>
      );
    });
  }
}

export default MonthSelector
